import { Link } from "react-router-dom";
import { apiClient, getCountryDetails } from "utils";
import { Skeleton } from "../ui/Skeleton";
import { Button } from "../ui/Button";
import { BlockConfirmation } from "pages/dashboard/views/Players";
import toast from "react-hot-toast";
import { useEffect, useState } from "react";
import { TbLoader } from "react-icons/tb";

export default function PersonalDetails(props: any) {
  const [isLoading, setIsLoading] = useState(props.isLoading);
  const [user, set_user] = useState({ ...props });
  const [blockingUser, setBlockingUser] = useState<any>({});
  const [open_block_confirmation, set_open_block_confirmation] =
    useState(false);
  useEffect(() => {
    setIsLoading(props.isLoading);
    set_user(props);
  }, [props]);

  const {
    username,
    firstname,
    lastname,
    id,
    email,
    status,
    blocked,
    nationality,
    telegram_user_name,
    x_profile_link,
    wallet,
  } = user;

  const handle_block_user = async (userId: string, reason?: string) => {
    try {
      // deleteMatchById({ matchId });
      if (user) {
        setBlockingUser({ [userId]: true });
        const _local_storage = localStorage.getItem("credential");
        if (!_local_storage || !JSON.parse(_local_storage).accessToken) {
          toast.error("UNAUTHORIZED");
          return;
        }
        const response = await apiClient.put(
          `/users/block`,
          {
            userId,
            reason,
            status: status === "active" ? "blocked" : "active",
          },
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(_local_storage).accessToken}`,
            },
          }
        );

        if (response.data.success) {
          set_user({
            ...user,
            status: status === "active" ? "blocked" : "active",
            blocked:
              status === "active"
                ? {
                    reason,
                    createdAt: Date.now(),
                  }
                : null,
          });
          toast.success(
            status === "active" ? "user blocked" : "user unblocked"
          );
          set_open_block_confirmation(false);
        } else {
          toast.error("Something went wrong");
        }
        setBlockingUser({ [userId]: false });
      }
    } catch (error: any) {
      console.log(error);
      setBlockingUser({ [userId]: false });

      toast.error(error.message || "OOOPPPSSS!!");
    }
  };

  return (
    <div className="mt-8 flex flex-col gap-8 px-8">
      <BlockConfirmation
        handle_submit={handle_block_user}
        loading={user && blockingUser[user.id]}
        user={user}
        status={user?.status}
        open={open_block_confirmation}
        onOpenChange={() => {
          set_open_block_confirmation(false);
        }}
      />
      <div className="flex flex-col gap-4">
        <h3 className="text-[28px] leading-none">Personal Details</h3>

        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">Fullname</p>
            {isLoading ? (
              <Skeleton className="h-4 w-32 rounded-md border border-border/20" />
            ) : (
              <p className="text-base">
                {firstname} {lastname}
              </p>
            )}
          </div>
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">email</p>
            {isLoading ? (
              <Skeleton className="h-4 w-56 rounded-md border border-border/20" />
            ) : (
              <p className="text-base">{email}</p>
            )}
          </div>
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">Username</p>
            {isLoading ? (
              <Skeleton className="h-4 w-20 rounded-md border border-border/20" />
            ) : (
              <p className="text-base">{username}</p>
            )}
          </div>
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">
              Country / Region of Residence
            </p>
            {isLoading ? (
              <div className="flex items-center gap-2">
                <Skeleton className="size-4 rounded-full border border-border/20" />
                <Skeleton className="h-4 w-14 rounded-md border border-border/20" />
              </div>
            ) : (
              <p className="flex items-center gap-2">
                <span className="text-2xl">
                  {getCountryDetails(nationality)?.emoji}
                </span>
                <span className="text-base">{nationality || "N/A"}</span>
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="text-[28px] leading-none">Social Network Details</h3>

        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">Telegram</p>
            {isLoading ? (
              <Skeleton className="h-4 w-32 rounded-md border border-border/20" />
            ) : telegram_user_name ? (
              <Link
                to={`https://t.me/${telegram_user_name}`}
                target="_blank"
                className="text-base"
              >
                {telegram_user_name}
              </Link>
            ) : (
              <p className="text-base">N/A</p>
            )}
          </div>
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">Twitter</p>
            {isLoading ? (
              <Skeleton className="h-4 w-32 rounded-md border border-border/20" />
            ) : x_profile_link ? (
              <Link
                to={`https://x.com/${x_profile_link}`}
                target="_blank"
                className="text-base"
              >
                {x_profile_link}
              </Link>
            ) : (
              <p className="text-base">N/A</p>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="text-[28px] leading-none">Wallet Details</h3>

        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">
              Binance ID or USDT
            </p>
            {isLoading ? (
              <Skeleton className="h-4 w-80 rounded-md border border-border/20" />
            ) : (
              <p className="text-base">{wallet || "N/A"}</p>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="text-[28px] leading-none">Others</h3>

        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
            <p className="text-base text-muted-foreground">status</p>
            {isLoading ? (
              <Skeleton className="h-4 w-56 rounded-md border border-border/20" />
            ) : (
              <div className="flex items-center gap-2">
                <p
                  className={
                    status === "active" ? "text-green-500" : "text-destructive"
                  }
                >
                  {status}
                </p>
                <Button
                  // disabled={loading}
                  type="submit"
                  variant={status === "active" ? "destructive" : "default"}
                  onClick={() => {
                    set_open_block_confirmation(true);
                  }}
                >
                  {blockingUser[id] ? (
                    <>
                      <TbLoader size={22} className="mr-1.5 animate-spin" />{" "}
                      {status === "active" ? "blocking" : "unblocking"}...
                    </>
                  ) : status === "active" ? (
                    "block user"
                  ) : (
                    "unblock user"
                  )}
                </Button>
              </div>
            )}
          </div>
          {blocked ? (
            <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
              <p className="text-base text-muted-foreground">reason</p>
              {isLoading ? (
                <Skeleton className="h-4 w-56 rounded-md border border-border/20" />
              ) : (
                <p className="text-base">{blocked.reason}</p>
              )}
            </div>
          ) : null}

          {blocked ? (
            <div className="flex items-center justify-between px-4 h-14 rounded-xl w-full border border-border/30 ring-1 ring-black/50 [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
              <p className="text-base text-muted-foreground">date</p>
              {isLoading ? (
                <Skeleton className="h-4 w-56 rounded-md border border-border/20" />
              ) : (
                <p className="text-base">
                  {new Date(blocked.createdAt).toLocaleString()}
                </p>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
