import { ReactNode, useState } from "react";
import { Button } from "../ui/Button";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../ui/Dialog";
import { X } from "lucide-react";

export default function FilterUser({
  children,
  on_apply,
  on_reset,
  status_value,
}: {
  children: ReactNode;
  on_reset: () => void;
  on_apply: (value: any) => void;
  status_value?: "active" | "blocked";
}) {
  const [filter, set_filter] = useState<any>(null);
  return (
    <Dialog onOpenChange={(val) => {}}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-[417px] p-0 outline-none border-transparent bg-background rounded-xl overflow-hidden">
        <DialogTitle className="h-10 px-4 flex items-center justify-between [background:_linear-gradient(180deg,_#212121_0%,_#1D1D1D_79.91%,_#1C1C1C_100%),linear-gradient(0deg,_rgba(255,_255,_255,_0.01),_rgba(255,_255,_255,_0.01))]">
          <p className="text-sm">Filter</p>
          <DialogClose>
            <X className="h-4 w-4" />
            <span className="sr-only">Close</span>
          </DialogClose>
        </DialogTitle>
        <DialogHeader className="px-4 py-6 max-h-[488px] overflow-y-auto">
          <div className="flex flex-col items-start gap-1 w-full">
            <label
              htmlFor="prediction"
              className="text-sm text-muted-foreground capitalize"
            >
              User Status
            </label>
            <div className="relative h-12 w-full">
              <select
                onChange={(e) => {
                  set_filter({
                    ...filter,
                    status:
                      e.target.value === "all" ? undefined : e.target.value,
                  });
                }}
                name="prediction"
                id="prediction"
                defaultValue={status_value}
                className="size-full border border-border/30 outline-none rounded-xl text-base px-4 bg-custom-gradient transition-colors disabled:pointer-events-none disabled:cursor-not-allowed placeholder:text-muted-foreground capitalize"
              >
                <option value="all">All</option>
                <option value="active">Active</option>
                <option value="blocked">Blocked</option>
              </select>
            </div>
          </div>
        </DialogHeader>

        <DialogFooter className="border-t border-border/30 w-full flex items-center justify-between p-4">
          <DialogClose asChild>
            <Button
              variant={"secondary"}
              type="button"
              onClick={() => {
                on_reset();
              }}
            >
              Reset
            </Button>
          </DialogClose>

          <DialogClose asChild>
            <Button
              type="button"
              onClick={() => {
                if (Object.keys(filter).length) {
                  on_apply(filter);
                }
              }}
            >
              Save Changes
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
